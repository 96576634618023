// extracted by mini-css-extract-plugin
export var addGroupFormBody = "create-tournament-group-module--add-group-form-body--BEvB2";
export var bodyBase = "create-tournament-group-module--body-base--sFv5c create-tournament-group-module--site-font--+htUl";
export var bodyLarge = "create-tournament-group-module--body-large--4WSwT create-tournament-group-module--body-base--sFv5c create-tournament-group-module--site-font--+htUl";
export var bodyLargeBold = "create-tournament-group-module--body-large-bold--TTjQQ create-tournament-group-module--body-base--sFv5c create-tournament-group-module--site-font--+htUl";
export var bodyRegular = "create-tournament-group-module--body-regular--QMYBO create-tournament-group-module--body-base--sFv5c create-tournament-group-module--site-font--+htUl";
export var bodyRegularBold = "create-tournament-group-module--body-regular-bold--nUHHS create-tournament-group-module--body-base--sFv5c create-tournament-group-module--site-font--+htUl";
export var bodySmall = "create-tournament-group-module--body-small--8wfqs create-tournament-group-module--body-base--sFv5c create-tournament-group-module--site-font--+htUl";
export var bodySmallBold = "create-tournament-group-module--body-small-bold--j8WFG create-tournament-group-module--body-base--sFv5c create-tournament-group-module--site-font--+htUl";
export var borderTop = "create-tournament-group-module--border-top--ZqXOL";
export var breakWordContainer = "create-tournament-group-module--break-word-container--8uXXq";
export var buttonIconBase = "create-tournament-group-module--button-icon-base--aV3y4";
export var clickLink = "create-tournament-group-module--click-link--byOQr";
export var dropdownBase = "create-tournament-group-module--dropdown-base--bU5FF";
export var dropdownSelectBase = "create-tournament-group-module--dropdown-select-base--nFtEX create-tournament-group-module--text-input--ku6FA";
export var error = "create-tournament-group-module--error--Jg4tO";
export var flexCol = "create-tournament-group-module--flex-col--wjXCg";
export var formErrorMessage = "create-tournament-group-module--form-error-message--jY7TY";
export var h3 = "create-tournament-group-module--h3--7YX+k";
export var h4 = "create-tournament-group-module--h4--zEHEQ";
export var hoverLink = "create-tournament-group-module--hover-link--fViaw";
export var hoverRow = "create-tournament-group-module--hover-row--nDZqU";
export var membershipContainer = "create-tournament-group-module--membership-container--Pbvfr create-tournament-group-module--flex-col--wjXCg create-tournament-group-module--primary-border--+Av4i";
export var membershipHeader = "create-tournament-group-module--membership-header--YrbiR";
export var membershipHeading = "create-tournament-group-module--membership-heading--C1Qkw";
export var membershipLabel = "create-tournament-group-module--membership-label--FGHQ+";
export var moreFiltersBorderClass = "create-tournament-group-module--more-filters-border-class--GO7kd";
export var pageBg = "create-tournament-group-module--page-bg--mr1oF";
export var pointer = "create-tournament-group-module--pointer--r+VSB";
export var primaryBorder = "create-tournament-group-module--primary-border--+Av4i";
export var shadowBoxLight = "create-tournament-group-module--shadow-box-light--U6D9k";
export var siteFont = "create-tournament-group-module--site-font--+htUl";
export var slideDownAndFade = "create-tournament-group-module--slideDownAndFade--OMoet";
export var slideLeftAndFade = "create-tournament-group-module--slideLeftAndFade--pThsT";
export var slideRightAndFade = "create-tournament-group-module--slideRightAndFade--psI8I";
export var slideUpAndFade = "create-tournament-group-module--slideUpAndFade--TwAbN";
export var statusDecoration = "create-tournament-group-module--status-decoration--kO695";
export var textInput = "create-tournament-group-module--text-input--ku6FA";
export var textInverted = "create-tournament-group-module--text-inverted--UsgG5";
export var textMediumDark = "create-tournament-group-module--text-medium-dark--qGJ8U";
export var tooltipFont = "create-tournament-group-module--tooltipFont--pUy79";
export var unstyledButton = "create-tournament-group-module--unstyled-button--JRlUo";