import React from 'react'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import { usePrivateRoute } from 'src/utils/auth'
import { Router, RouteComponentProps } from '@reach/router'
import CreateTournamentGroup from 'src/components/create-tournament-group/create-tournament-group'

const CreateTournamentGroupPage = () => {
  usePrivateRoute()
  return (
    <Router basepath="/tournaments/groups/create-group">
      <CreateTournamentGroupRoute default />
    </Router>
  )
}

type CreateTournamentGroupRouteProps = RouteComponentProps

const CreateTournamentGroupRoute: React.FC<CreateTournamentGroupRouteProps> = () => {
  return (
    <Layout>
      <SEO title="Create Tournament Group" />
      <CreateTournamentGroup />
    </Layout>
  )
}

export default CreateTournamentGroupPage
