import React, { useMemo } from 'react'
import { Form, Formik, useFormikContext, ErrorMessage } from 'formik'
import * as styles from './create-tournament-group.module.less'
import * as Yup from 'yup'
import {
  TextField,
  DropdownField,
  RangeContainer,
  DateField,
  Label,
  SwitchField
} from '../formik-fields/formik-fields'
import { Category } from 'src/graphql-types/globalTournamentTypes'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import LevelsDropdown from '../levels-dropdown/levels-dropdown'
import { useOrgId } from 'src/apollo/local-state'
import { useMutation } from '@apollo/client'
import { CREATE_TOURNAMENT_POOL } from './create-tournament-group-queries'
import {
  CreateTournamentPool,
  CreateTournamentPoolVariables
} from 'src/graphql-types/CreateTournamentPool'
import { graphQLDateString } from 'src/utils/helper/tournnaments-utils'
import FormErrorMessage from '../form-error-message/form-error-message'
import Panel from '../panel/panel'
import PageHeader from '../page-header/page-header'
import Button from '../button/button'
import { clearTPs } from '../tournament-groups-overview/tournament-groups-overview-queries'
import { navigate } from 'gatsby'
import TimezoneDropdown from '../timezone-dropdown/timezone-dropdown'
import { tournamentsClient } from 'src/apollo/client'

interface FormData {
  name: string
  category: Category
  levelId: string
  fromDate: string
  toDate: string
  entriesCloseDate: string
  entriesCloseTime: number
  entriesOpenDate: string
  entriesOpenTime: number
  priorityPlayerSelection: boolean
  maxTournamentsPerPlayer: number
  maxDivisionsPerPlayerPerTournament: number
  countDivisionsAsTournaments: boolean
  websitePromotion: boolean
  timeZone: string
}

interface Props {
  onComplete?: () => void
}

const CreateTournamentGroup: React.FC<Props> = ({ onComplete }) => {
  const { t } = useTranslation()
  const orgId = useOrgId()

  const [createTournamentGroup, { loading, error, data }] = useMutation<
    CreateTournamentPool,
    CreateTournamentPoolVariables
  >(CREATE_TOURNAMENT_POOL, { client: tournamentsClient })

  const validationSchema = useMemo(() => {
    return Yup.object({
      name: Yup.string().required(t('is required', { type: 'group name' })),
      fromDate: Yup.string(),
      toDate: Yup.string().test('toIsAfterFrom', t('to is before from'), function(to: string) {
        const from = this.resolve(Yup.ref('fromDate'))
        return new Date(to) > new Date(from)
      }),
      levelId: Yup.string().required(t('is required', { type: 'level' })),
      maxDivisionsPerPlayerPerTournament: Yup.number()
        .required(t('is required', { type: 'max divisions per player per tournaments' }))
        .min(1)
        .typeError(t('must be a number', { type: 'max divisions per player per tournaments' }))
        .positive(
          t('must be a positive number', { type: 'max divisions per player per tournaments' })
        ),
      maxTournamentsPerPlayer: Yup.number()
        .required(t('is required', { type: 'max tournaments per player' }))
        .min(1)
        .typeError(t('must be a number', { type: 'max tournaments per player' }))
        .positive(t('must be a positive number', { type: 'max tournaments per player' }))
    })
  }, [])

  const initialValues = useMemo<FormData>(() => {
    return {
      name: '',
      category: Category.JUNIOR,
      levelId: '',
      fromDate: new Date().toISOString(),
      toDate: moment()
        .add(1, 'month')
        .toISOString(),
      entriesOpenDate: moment().toISOString(),
      entriesOpenTime: 0,
      entriesCloseDate: moment().toISOString(),
      entriesCloseTime: 0,
      priorityPlayerSelection: false,
      maxDivisionsPerPlayerPerTournament: 0,
      maxTournamentsPerPlayer: 0,
      countDivisionsAsTournaments: false,
      websitePromotion: false,
      timeZone: 'America/New_York'
    }
  }, [t])

  const onSubmit = async (values: FormData) => {
    const {
      toDate,
      fromDate,
      entriesCloseDate,
      entriesCloseTime,
      entriesOpenDate,
      entriesOpenTime,
      ...otherValues
    } = values

    const openTime = moment(values.entriesOpenDate)
    const closeTime = moment(values.entriesCloseDate)

    const closeTimeMidnight = closeTime.clone().startOf('day')
    const openTimeMidnight = openTime.clone().startOf('day')

    const diffClose = closeTime.diff(closeTimeMidnight, 'minutes')
    const diffOpen = openTime.diff(openTimeMidnight, 'minutes')

    const res = await createTournamentGroup({
      variables: {
        pool: {
          organisationId: orgId,
          toDate: graphQLDateString(toDate),
          fromDate: graphQLDateString(fromDate),
          entriesOpenDate: openTime.format('YYYY-MM-DD'),
          entriesOpenTime: diffOpen,
          entriesCloseDate: closeTime.format('YYYY-MM-DD'),
          entriesCloseTime: diffClose,
          ...otherValues
        }
      },
      update: clearTPs
    })
    if (res) navigate('/tournaments/groups')
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      <Form>
        <CreateGroupForm submitting={loading} error={error?.message} data={data} />
      </Form>
    </Formik>
  )
}

interface CreateGroupFormProps {
  submitting: boolean
  error?: string
  data?: CreateTournamentPool
}

const CreateGroupForm: React.FC<CreateGroupFormProps> = ({ submitting, error, data }) => {
  const { t } = useTranslation()
  const { values, setFieldValue, errors, touched } = useFormikContext<FormData>()

  return (
    <Panel>
      <PageHeader title={t('create tournament group')} />
      <div className={styles.addGroupFormBody}>
        <TextField name={'name'} label={t('group name')} flushTop />
        <DropdownField
          name={'category'}
          label={t('circuit')}
          options={[
            { label: t('junior'), value: Category.JUNIOR },
            { label: t('adult'), value: Category.ADULT },
            { label: t('wheelchair'), value: Category.WHEELCHAIR },
            { label: t('wtn play'), value: Category.WTN_PLAY }
          ]}
        />
        <Label>{t('level')}</Label>
        <LevelsDropdown
          category={values.category}
          selected={values.levelId}
          onChange={v => setFieldValue('levelId', v)}
          allowAny={false}
          defaultOnCategoryChange
          defaultOnEmptyLevel
          dropdownProps={{ fluid: true }}
        />
        <ErrorMessage name={'levelId'} component={FormErrorMessage} />
        <RangeContainer label={t('date range')} errorName={'toDate'}>
          <DateField
            name={'fromDate'}
            datePickerProps={{ spacing: { margins: { md: 'right' } } }}
          />
          -
          <DateField
            name={'toDate'}
            datePickerProps={{
              spacing: { margins: { md: 'left' } },
              openToDate: new Date(values.fromDate)
            }}
          />
        </RangeContainer>
        <Label>{t('registration open')}</Label>
        <DateField
          name={'entriesOpenDate'}
          datePickerProps={{ showTimeSelect: true, spacing: { margins: { md: 'right' } } }}
        />
        <Label>{t('registration close')}</Label>
        <DateField
          name={'entriesCloseDate'}
          datePickerProps={{ showTimeSelect: true, spacing: { margins: { md: 'right' } } }}
        />
        <Label>{t('timezone')}</Label>
        <TimezoneDropdown name="timeZone" />
        <SwitchField name="priorityPlayerSelection" label={t('priority player selection')} />
        <TextField name={'maxTournamentsPerPlayer'} label={t('max tournaments per player')} />
        <TextField
          name={'maxDivisionsPerPlayerPerTournament'}
          label={t('max divisions per player per tournaments')}
        />
        <SwitchField
          name="countDivisionsAsTournaments"
          label={t('count divisions as tournaments')}
        />
        <SwitchField name="websitePromotion" label={t('website promotion')} />
      </div>
      <Button size="md" spacing={{ margins: { auto: 'right', md: 'top' } }} loading={submitting}>
        {t('create')}
      </Button>
    </Panel>
  )
}

export default CreateTournamentGroup
