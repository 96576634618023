import gql from 'graphql-tag'

export const GET_LEVELS = gql`
  query GetLevels {
    levels {
      id
      category
      name
      orderIndex
      sanctionType
    }
  }
`
