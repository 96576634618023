import React, { useMemo, useEffect, useState } from 'react'
import { Category, SanctionType } from 'src/graphql-types/globalTournamentTypes'
import { useQuery } from '@apollo/client'
import { GET_LEVELS } from './levels-dropdown-queries'
import { GetLevels } from 'src/graphql-types/GetLevels'
import { useTranslation } from 'react-i18next'
import Dropdown, { Props as DropdownProps } from '../dropdown/dropdown'
import { FILTER_OFF } from 'src/utils/constants/global-constants'
import { tournamentsClient } from 'src/apollo/client'

interface LevelsDropdownProps {
  category?: Category
  selected: string
  onChange?: (level: string) => void
  dropdownProps?: Partial<DropdownProps>
  allowAny?: boolean
  defaultOnCategoryChange?: boolean
  defaultOnEmptyLevel?: boolean
}

const LevelsDropdown: React.FC<LevelsDropdownProps> = ({
  category,
  onChange,
  selected,
  dropdownProps,
  allowAny = true,
  defaultOnCategoryChange,
  defaultOnEmptyLevel
}) => {
  const { t } = useTranslation()
  const { data: levelData, loading: loadingLevels } = useQuery<GetLevels>(GET_LEVELS, {
    client: tournamentsClient
  })
  const levels = useMemo(() => {
    const l = levelData?.levels
    if (!l) return [{ label: t('loading levels'), value: 'loading' }]
    const options = [
      ...[...l]
        .filter(l => l.sanctionType !== SanctionType.NONE && l.category === category)
        .sort((l1, l2) => l1.orderIndex - l2.orderIndex)
        .map(l => ({ label: l.name, value: l.id }))
    ]
    if (allowAny) {
      options.unshift({ label: t('any'), value: FILTER_OFF })
    }
    return options
  }, [levelData, category, t])

  const [shouldDefault, setShouldDefault] = useState(false)

  // If the category (and therefore available levels) changes, and defaultOnCategoryChange
  // is set, automatically update the controlled selected level (by calling onChange) to a
  // default value. The default value will be 'any' if allowed, otherwise Level 1 of the category.
  useEffect(() => {
    if (defaultOnCategoryChange && levelData) {
      setShouldDefault(true)
    }
  }, [levels])

  // If the level is set to an empty string and defaultOnEmptyLevel is set, also default
  // the level as descibred above.
  useEffect(() => {
    if (defaultOnEmptyLevel && levelData && !selected) {
      setShouldDefault(true)
    }
  }, [selected])

  // This ensures that both defaultOnEmptyLevel and defaultOnCategoryChange won't both reset
  // level in the same render. If either or both set shouldDefault then it will only update once.
  useEffect(() => {
    if (shouldDefault && levels) {
      onChange?.(allowAny ? FILTER_OFF : levels[0]?.value)
      setShouldDefault(false)
    }
  }, [shouldDefault])

  return (
    <Dropdown
      selected={selected}
      disabled={loadingLevels}
      options={levels}
      onSelect={l => onChange?.(l.value)}
      {...dropdownProps}
    />
  )
}

export default LevelsDropdown
